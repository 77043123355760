import {tokens} from './tokens/tokens';

export const color = {
  transparent: 'transparent',
  white: tokens.COLOR_CORE_WHITE,
  grey50: tokens.COLOR_CORE_GREY_50,
  grey100: tokens.COLOR_CORE_GREY_100,
  grey200: tokens.COLOR_CORE_GREY_200,
  grey300: tokens.COLOR_CORE_GREY_300,
  grey400: tokens.COLOR_CORE_GREY_400,
  grey500: tokens.COLOR_CORE_GREY_500,
  grey600: tokens.COLOR_CORE_GREY_600,
  grey700: tokens.COLOR_CORE_GREY_700,
  grey800: tokens.COLOR_CORE_GREY_800,
  black: tokens.COLOR_CORE_BLACK,
  trueBlack: tokens.COLOR_CORE_TRUE_BLACK,
  neon500: tokens.COLOR_CORE_NEON_500,
  neon600: tokens.COLOR_CORE_NEON_600,
  neon700: tokens.COLOR_CORE_NEON_700,
  pink100: tokens.COLOR_CORE_PINK_100,
  pink200: tokens.COLOR_CORE_PINK_200,
  pink300: tokens.COLOR_CORE_PINK_300,
  pink400: tokens.COLOR_CORE_PINK_400,
  pink500: tokens.COLOR_CORE_PINK_500,
  pink600: tokens.COLOR_CORE_PINK_600,
  pink700: tokens.COLOR_CORE_PINK_700,
  pink800: tokens.COLOR_CORE_PINK_800,
  red100: tokens.COLOR_CORE_RED_100,
  red200: tokens.COLOR_CORE_RED_200,
  red300: tokens.COLOR_CORE_RED_300,
  red400: tokens.COLOR_CORE_RED_400,
  red500: tokens.COLOR_CORE_RED_500,
  red600: tokens.COLOR_CORE_RED_600,
  red700: tokens.COLOR_CORE_RED_700,
  red800: tokens.COLOR_CORE_RED_800,
  scarlet100: tokens.COLOR_CORE_SCARLET_100,
  scarlet200: tokens.COLOR_CORE_SCARLET_200,
  scarlet300: tokens.COLOR_CORE_SCARLET_300,
  scarlet400: tokens.COLOR_CORE_SCARLET_400,
  scarlet500: tokens.COLOR_CORE_SCARLET_500,
  scarlet600: tokens.COLOR_CORE_SCARLET_600,
  scarlet700: tokens.COLOR_CORE_SCARLET_700,
  scarlet800: tokens.COLOR_CORE_SCARLET_800,
  orange100: tokens.COLOR_CORE_ORANGE_100,
  orange200: tokens.COLOR_CORE_ORANGE_200,
  orange300: tokens.COLOR_CORE_ORANGE_300,
  orange400: tokens.COLOR_CORE_ORANGE_400,
  orange500: tokens.COLOR_CORE_ORANGE_500,
  orange600: tokens.COLOR_CORE_ORANGE_600,
  orange700: tokens.COLOR_CORE_ORANGE_700,
  orange800: tokens.COLOR_CORE_ORANGE_800,
  cactus100: tokens.COLOR_CORE_CACTUS_100,
  cactus200: tokens.COLOR_CORE_CACTUS_200,
  cactus300: tokens.COLOR_CORE_CACTUS_300,
  cactus400: tokens.COLOR_CORE_CACTUS_400,
  cactus500: tokens.COLOR_CORE_CACTUS_500,
  cactus600: tokens.COLOR_CORE_CACTUS_600,
  cactus700: tokens.COLOR_CORE_CACTUS_700,
  cactus800: tokens.COLOR_CORE_CACTUS_800,
  green100: tokens.COLOR_CORE_GREEN_100,
  green200: tokens.COLOR_CORE_GREEN_200,
  green300: tokens.COLOR_CORE_GREEN_300,
  green400: tokens.COLOR_CORE_GREEN_400,
  green500: tokens.COLOR_CORE_GREEN_500,
  green600: tokens.COLOR_CORE_GREEN_600,
  green700: tokens.COLOR_CORE_GREEN_700,
  green800: tokens.COLOR_CORE_GREEN_800,
  indigo100: tokens.COLOR_CORE_INDIGO_100,
  indigo200: tokens.COLOR_CORE_INDIGO_200,
  indigo300: tokens.COLOR_CORE_INDIGO_300,
  indigo400: tokens.COLOR_CORE_INDIGO_400,
  indigo500: tokens.COLOR_CORE_INDIGO_500,
  indigo600: tokens.COLOR_CORE_INDIGO_600,
  indigo700: tokens.COLOR_CORE_INDIGO_700,
  indigo800: tokens.COLOR_CORE_INDIGO_800,
};

export const elevation = {
  level1: tokens.ELEVATION_LEVEL1,
  level2: tokens.ELEVATION_LEVEL2,
  level3: tokens.ELEVATION_LEVEL3,
  level4: tokens.ELEVATION_LEVEL4,
  level5: tokens.ELEVATION_LEVEL5,
};

export const spacing = {
  '2XS': tokens.SPACING_2XS,
  XS: tokens.SPACING_XS,
  SM: tokens.SPACING_SM,
  MD: tokens.SPACING_MD,
  LG: tokens.SPACING_LG,
  XL: tokens.SPACING_XL,
  '2XL': tokens.SPACING_2XL,
  '3XL': tokens.SPACING_3XL,
  '4XL': tokens.SPACING_4XL,
  '5XL': tokens.SPACING_5XL,
};

export const margin = {
  mobile: {
    page: spacing.MD,
  },
  desktop: {
    page: spacing.LG,
    panel: spacing.LG,
  },
};

export const borderRadius = {
  XS: tokens.BORDER_RADIUS_XS,
  SM: tokens.BORDER_RADIUS_SM,
  MD: tokens.BORDER_RADIUS_MD,
  LG: tokens.BORDER_RADIUS_LG,
  XL: tokens.BORDER_RADIUS_XL,
  '2XL': tokens.BORDER_RADIUS_2XL,
};

export const borderWidth = {
  1: tokens.BORDER_WIDTH_1,
  2: tokens.BORDER_WIDTH_2,
  3: tokens.BORDER_WIDTH_3,
  4: tokens.BORDER_WIDTH_4,
};

export const fontSize = {
  25: tokens.FONT_SIZE_25,
  50: tokens.FONT_SIZE_50,
  100: tokens.FONT_SIZE_100,
  200: tokens.FONT_SIZE_200,
  300: tokens.FONT_SIZE_300,
  400: tokens.FONT_SIZE_400,
  500: tokens.FONT_SIZE_500,
  600: tokens.FONT_SIZE_600,
  700: tokens.FONT_SIZE_700,
  800: tokens.FONT_SIZE_800,
  900: tokens.FONT_SIZE_900,
  1000: tokens.FONT_SIZE_1000,
  1100: tokens.FONT_SIZE_1100,
};

export const lineHeight = {
  25: tokens.FONT_LINE_HEIGHT_25,
  50: tokens.FONT_LINE_HEIGHT_50,
  100: tokens.FONT_LINE_HEIGHT_100,
  200: tokens.FONT_LINE_HEIGHT_200,
  300: tokens.FONT_LINE_HEIGHT_300,
  400: tokens.FONT_LINE_HEIGHT_400,
  500: tokens.FONT_LINE_HEIGHT_500,
  600: tokens.FONT_LINE_HEIGHT_600,
  700: tokens.FONT_LINE_HEIGHT_700,
  800: tokens.FONT_LINE_HEIGHT_800,
  900: tokens.FONT_LINE_HEIGHT_900,
  1000: tokens.FONT_LINE_HEIGHT_1000,
  1100: tokens.FONT_LINE_HEIGHT_1100,
};

export const fontWeight = {
  regular: tokens.FONT_WEIGHT_REGULAR,
  medium: tokens.FONT_WEIGHT_MEDIUM,
  bold: tokens.FONT_WEIGHT_BOLD,
};

export const letterSpacing = {
  ['2XS']: tokens.FONT_LETTER_SPACING_2XS,
  XS: tokens.FONT_LETTER_SPACING_XS,
  SM: tokens.FONT_LETTER_SPACING_SM,
  MD: tokens.FONT_LETTER_SPACING_MD,
  LG: tokens.FONT_LETTER_SPACING_LG,
};

export const fonts = {
  primary: tokens.FONT_TYPEFACE_PRIMARY,
  secondary: 'gimlet-display-condensed, sans-serif',
  dm: 'DM Sans, sans-serif',
  gimletCondensed: 'gimlet-display-condensed, sans-serif',
};

export const sizing = {
  mobile: {
    navHeight: '80px',
    altNavHeight: '65px',
    footerControlsHeight: '100px',
  },
  desktop: {
    navHeight: '80px',
    altNavHeight: '80px',
    sessionCreatorMenuWidth: '240px',
    creatorSideMenuWidth: '288px',
    creatorSideMenuCondensedWidth: '72px',
    maxGridWidth: '940px',
    maxBookingWidth: '1128px',
    leftPanel: '616px',
    rightPanel: '300px',
    sessionSidePanelS: '345px',
    sessionSidePanelM: '375px',
    footerControlsHeight: '84px',
  },
};

export const opacity = {
  50: tokens.OPACITY_50,
  80: tokens.OPACITY_80,
  90: tokens.OPACITY_90,
};
